import React from 'react';
import { alpha, useTheme } from '@mui/material';

import { Chart } from 'react-chartjs-2';

import map from 'lodash/map';
import keys from 'lodash/keys';
import values from 'lodash/values';

import {
  HOUR_LABELS,
  DAYS_OF_WEEK_LABELS,
  GROUPING_LABEL_HOURS,
  GROUPING_LABEL_DAY_OF_WEEK
} from '../../const.js';

import { getTooltipDefaults } from './helpers.js';


const RatingChangeByPeriodChart = ({ groupingLabel, groupedData })=> {
  const theme = useTheme();

  return (
    <Chart
      options={{
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: theme.palette.text.secondary
            }
          },
          tooltip: getTooltipDefaults(theme),
        },
        elements: {
          point: {
            borderWidth: 1,
            radius: 1,
            backgroundColor: theme.palette.background.default
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              drawOnChartArea: true,
            },
            ticks: {
              color: theme.palette.text.secondary,
            },
            title: {
              display: false,
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: theme.palette.background.default,
            },
            grid: {
              color: theme.palette.background.default,
            },
            ticks: {
              color: theme.palette.text.secondary,
            },
            title: {
              display: true,
              text: 'Rounds played',
              color: theme.palette.text.secondary,
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: theme.palette.text.secondary,
            },
            title: {
              display: true,
              text: 'Avg. Change',
              color: theme.palette.text.secondary,
            }
          },
        },
      }}
      data={{
        labels: map(keys(groupedData), (key)=> {
          return (
            groupingLabel === GROUPING_LABEL_HOURS ?
              HOUR_LABELS[key]
            : groupingLabel === GROUPING_LABEL_DAY_OF_WEEK ?
              DAYS_OF_WEEK_LABELS[key]
            :
              key
          )
        }),
        datasets: [
          {
            label: 'Avg. Change',
            type: 'line',
            fill: 'start',
            data: values(map(groupedData, 'average_change')),
            borderColor: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            yAxisID: 'y1',
            tension: 0.3,
          },
          {
            label: 'Rounds Played',
            type: 'bar',
            data: values(map(groupedData, 'count')),
            borderColor: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.dark,
            yAxisID: 'y',
          },
        ],
      }}
    />
  );
};

export default RatingChangeByPeriodChart;
