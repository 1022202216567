import React from 'react';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';


const pages = ['Summary', 'By Period'];
const settings = ['Logout'];

const TopBar = ({ currentTab, onNavBtnClick })=> {
  const [primaryNavEl, setPrimaryNavEl] = React.useState(null);
  const [settingsEl, setSettingsEl] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setPrimaryNavEl(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setSettingsEl(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setPrimaryNavEl(null);
  };

  const handleCloseUserMenu = () => {
    setSettingsEl(null);
  };

  return (
    <AppBar position="static" sx={{  backgroundColor: 'background.paper', marginBottom: '30px' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              textTransform: 'uppercase',
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            DGV Stats
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>

            <Menu
              keepMounted
              id="menu-appbar"
              anchorEl={primaryNavEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
              transformOrigin={{ vertical: 'top', horizontal: 'left', }}
              open={Boolean(primaryNavEl)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' }, }}
            >
              {
                pages.map((page, idx) => (
                  <MenuItem
                    key={page}
                    selected={currentTab === idx}
                    onClick={(e)=> {
                      onNavBtnClick(idx);
                      handleCloseNavMenu(e);
                    }}
                  >
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))
              }
            </Menu>
          </Box>


          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              textTransform: 'uppercase',
              flexGrow: 1,
              fontWeight: 700,
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            DGV Stats
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {
              pages.map((page, idx) => (
                <Button
                  key={page}
                  onClick={(e)=> {
                    onNavBtnClick(idx);
                    handleCloseNavMenu(e);
                  }}
                  sx={{
                    my: 2,
                    display: 'block',
                    color: (
                      currentTab === idx ?
                        theme=> theme.palette.primary.main
                      :
                        'white'
                    )
                  }}
                >
                  {page}
                </Button>
              ))
            }
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Dan" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>

            <Menu
              keepMounted
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={settingsEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
              transformOrigin={{ vertical: 'top', horizontal: 'right', }}
              open={Boolean(settingsEl)}
              onClose={handleCloseUserMenu}
            >
              {
                settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))
              }
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default TopBar;
