import React from 'react';
import { useTheme } from '@mui/material';

import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import sum from 'lodash/sum';
import map from 'lodash/map';
import keys from 'lodash/keys';
import round from 'lodash/round';
import values from 'lodash/values';

import { getTooltipDefaults } from './helpers.js';

const OutcomePercentagesChart = ({ groupedRatingChanges })=> {
  const theme = useTheme();

  return (
    <Doughnut
      plugins={[ChartDataLabels]}
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
            labels: {
              color: theme.palette.text.secondary
            }
          },
          datalabels: {
            anchor: 'center',
            textAlign: 'center',
            color: theme.palette.text.primary,
            display: function(context) {
              var dataset = context.dataset;
              var value = dataset.data[context.dataIndex];
              var totalRounds = sum(dataset.data);

              // show labels if at least 5% of the chart
              return round(value / totalRounds, 2) >= 0.05;
            },
            formatter: function(value, context) {
              const label = context.chart.data.labels[context.dataIndex];
              return label.split(' ').join('\n');
            },
            font: {
              size: 13,
              family: '"Roboto","Helvetica","Arial",sans-serif',
            },
          },
          tooltip: getTooltipDefaults(theme),
          scales: {
            y: {
              title: theme.palette.secondary.main
            }
          }
        },
      }}
      data={{
        labels: keys(groupedRatingChanges),
        datasets: [
          {
            label: 'Result',
            data: map(values(groupedRatingChanges), 'total'),
            backgroundColor: [
              theme.palette.success.main, // Positive Finish
              theme.palette.error.main, // Negative Finish
              theme.palette.info.main, // Even Finishes
            ],
            borderWidth: 2,
            borderColor: theme.palette.background.paper
          }
        ]
      } }
    />
  );
};

export default OutcomePercentagesChart;
