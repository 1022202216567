import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#161c24',
      paper: '#212b36',
    },
    text: {
      primary: '#f9fafb',
      secondary: '#919eab',
      disabled: '#454f5b',
      hint: '#dfe3e8',
    },
    primary: {
      main: '#00A76F',
      lighter: '#C8FAD6',
      darker: '#004B50',
    },
    secondary: {
      main: '#8E33FF',
      lighter: '#EFD6FF',
      darker: '#27097A',
    },
    error: {
      main: '#FF5630',
      lighter: '#FFE9D5',
      darker: '#7A0916',
    },
    warning: {
      main: '#FFAB00',
      lighter: '#FFF5CC',
      darker: '#7A4100',
    },
    info: {
      main: '#00B8D9',
      lighter: '#CAFDF5',
      darker: '#003768',
    },
    success: {
      main: '#22C55E',
      lighter: '#FFE9D5',
      darker: '#FFE9D5',
    },
    divider: '#454F5B',
  },
  grid: {
    spacing: 4
  }
});

/*****************************************************************************
 ** Style Constants
*****************************************************************************/

export const dramaticBoxShadow = 'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px';
export const basicDropShadow = 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px';

export const boxWithShadowStyle = {
  boxShadow: dramaticBoxShadow,
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  borderRadius: '8px'
};

export default theme;
