export const getTooltipDefaults = (theme)=> ({
  titleColor: theme.palette.text.primary,
  bodyColor: theme.palette.text.secondary,
  bodySpacing: 10,
  titleMarginBottom: 10,
  borderColor: theme.palette.background.default,
  boxPadding: 5,
  padding: 20,
  backgroundColor: theme.palette.background.default,
});
