import React from 'react';

import TableCell from '@mui/material/TableCell';

import { StyledTableRow } from '../shared/StyledTable.js';


const SummaryDataTableRow = ({ label, value, valueColor })=> (
  <StyledTableRow>
    <TableCell component="th" scope="row" sx={{ color: theme => theme.palette.text.secondary }}>
      { label }
    </TableCell>
    <TableCell component="td" scope="row" sx={{ color: valueColor, textAlign: 'right' }}>
      { value }
    </TableCell>
  </StyledTableRow>
);

export default SummaryDataTableRow;
