import React from 'react';
import { useTheme } from '@mui/material';

import TableCell from '@mui/material/TableCell';

import map from 'lodash/map';
import keys from 'lodash/keys';
import noop from 'lodash/noop';

import {
  HOUR_LABELS,
  DAYS_OF_WEEK_LABELS,
  GROUPING_LABEL_HOURS,
  GROUPING_LABEL_DAY_OF_WEEK
} from '../../const.js';

import StyledTable, { StyledTableRow } from './StyledTable.js';


const GroupedTableRow = ({ groupedData, mapValue, rowLabel, appendPercent, valueSxFunc=noop })=> (
  <StyledTableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell
      component="th"
      scope="row"
      sx={{
        minWidth: 150,
        color: theme => theme.palette.text.secondary
      }}
    >
      { rowLabel }
    </TableCell>

    {
      map(map(groupedData, mapValue), (value, key)=> (
        <TableCell key={key} sx={valueSxFunc(value)}>
          { value }{ appendPercent ? '%' : null }
        </TableCell>
      ))
    }
  </StyledTableRow>
);


const PeriodMetaTable = ({ groupedData, groupingLabel })=> {
  const theme = useTheme();

  return (
    <StyledTable
      tableSx={{ minWidth: 650 }}
      headers={
        [
          '',
          ...map(keys(groupedData), (key)=> (
            groupingLabel === GROUPING_LABEL_HOURS ?
              HOUR_LABELS[key]
            : groupingLabel === GROUPING_LABEL_DAY_OF_WEEK ?
              DAYS_OF_WEEK_LABELS[key]
            :
              key
          ))
        ]
      }
    >
      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="count"
        rowLabel="Games Played"
      />

      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="percent_of_total"
        rowLabel="Games %"
        appendPercent={true}
      />

      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="total_change"
        rowLabel="Total Change"
        valueSxFunc={(change)=> {
          return { color: change > 0 ? theme.palette.success.main : change < 0 ? theme.palette.error.main : null };
        }}
      />

      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="average_change"
        rowLabel="Avg Change"
        valueSxFunc={(change)=> {
          return { color: change > 0 ? theme.palette.success.main : change < 0 ? theme.palette.error.main : null };
        }}
      />

      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="gain_percent"
        rowLabel="Gain %"
        appendPercent={true}
        valueSxFunc={(percent)=> {
          return { color: percent > 0 ? theme.palette.success.main : null };
        }}
      />

      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="even_percent"
        rowLabel="Even %"
        appendPercent={true}
        valueSxFunc={(percent)=> {
          return { color: percent > 0 ? theme.palette.info.main : null };
        }}
      />

      <GroupedTableRow 
        groupedData={groupedData}
        mapValue="loss_percent"
        rowLabel="Loss %"
        appendPercent={true}
        valueSxFunc={(percent)=> {
          return { color: percent > 0 ? theme.palette.error.main : null };
        }}
      />
    </StyledTable>
  );
};

export default PeriodMetaTable;
