import React from 'react';
import { useTheme } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';
import CircleIcon from '@mui/icons-material/Circle';

import StyledTable from './StyledTable.js';
import SummaryDataTableRow  from './SummaryDataTableRow.js';
import StyledGridItemWithHeader from './StyledGridItemWithHeader.js';


const StreakDisplay = ({ streak, color, appendPlusSign })=> (
  streak ?
    <Tooltip
      title={
        <span>
          Matches { streak.start_match_num } - { streak.end_match_num }
        </span>
      }
    >
      <span>{ streak.len } rounds</span>

      <span style={{ display: 'inline-block', color, width: '70px' }}>
        { appendPlusSign ? '+' : ' ' }{ streak.points_gained } pts
      </span>
    </Tooltip>
  :
    '0'
);


const StreakData = ({ summaryData })=> {
  const theme = useTheme();

  return (
    <StyledGridItemWithHeader header="Streaks" gridItemProps={{ xs: 12, sm: 12, md: 6 }}>
      <StyledTable headers={[]}>

        <SummaryDataTableRow
          label={<span>Only <CircleIcon sx={{ fontSize: 10, color: theme.palette.success.main }} />  Streak</span>}
          value={
            <StreakDisplay
              streak={summaryData.longest_green_streak}
              appendPlusSign={true}
              color={theme.palette.success.main}
            />
          }
        />

        <SummaryDataTableRow
          label={<span>Only <CircleIcon sx={{ fontSize: 10, color: theme.palette.error.main }} />  Streak</span>}
          value={
            <StreakDisplay
              streak={summaryData.longest_red_streak}
              appendPlusSign={false}
              color={theme.palette.error.main}
            />
          }
        />

        <SummaryDataTableRow
          label={<span>No <CircleIcon sx={{ fontSize: 10, color: theme.palette.error.main }} />  Streak</span>}
          value={
            <StreakDisplay
              streak={summaryData.longest_non_red_streak}
              appendPlusSign={true}
              color={theme.palette.success.main}
            />
          }
        />

        <SummaryDataTableRow
          label={<span>No <CircleIcon sx={{ fontSize: 10, color: theme.palette.success.main }} />  Streak</span>}
          value={
            <StreakDisplay
              streak={summaryData.longest_non_green_streak}
              appendPlusSign={false}
              color={theme.palette.error.main}
            />
          }
        />

      </StyledTable>
    </StyledGridItemWithHeader>
  );
};

export default StreakData;
