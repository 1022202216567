import React from 'react';
import { alpha, useTheme } from '@mui/material';

import { Line } from 'react-chartjs-2';

import map from 'lodash/map';
import range from 'lodash/range';

import { getTooltipDefaults } from './helpers.js';


const RatingByRoundChart = ({ jsonData })=> {
  const theme = useTheme();

  return (
    <Line
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top'
          },
          title: {
            display: false,
            text: 'Disc Golf Valley Multiplayer Rating Chart'
          },
          tooltip: getTooltipDefaults(theme)
        },
        elements: {
          point: {
            borderWidth: 1,
            radius: 1,
            backgroundColor: theme.palette.background.default
          }
        },
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: theme.palette.background.default,
            },
            grid: {
              color: theme.palette.background.default,
            },
            ticks: {
              stepSize: 50,
              color: theme.palette.text.secondary,
            },
            title: {
              display: true,
              text: 'Rating',
              color: theme.palette.text.secondary,
            }
          },
          x: {
            type: 'linear',
            display: false,
            position: 'right',
            grid: {
              drawOnChartArea: true,
            },
            title: {
              display: true,
              text: 'Match Number #'
            }
          }
        }
      }}
      data={{
        labels: range(jsonData.length),
        datasets: [
          {
            label: 'Current Rating',
            data: map(jsonData, 'current_rating'),
            borderColor: theme.palette.success.main,
            backgroundColor: alpha(theme.palette.success.main, 0.5),
          }
        ],
      }}
    />
  );
};

export default RatingByRoundChart;
