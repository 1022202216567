import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import ShieldIcon from '@mui/icons-material/Shield';

import { orange, grey, yellow, purple } from '@mui/material/colors';

import {
  DIVISION_ROOKIE_VALUE,
  DIVISION_PROSPECT_VALUE,
  DIVISION_EXPERT_VALUE,
  DIVISION_CHAMPION_VALUE,
  DIVISION_ROOKIE_LABEL,
  DIVISION_PROSPECT_LABEL,
  DIVISION_EXPERT_LABEL,
  DIVISION_CHAMPION_LABEL,
  DIVISION_PROSPECT_THRESHOLD,
  DIVISION_EXPERT_THRESHOLD,
  DIVISION_CHAMPION_THRESHOLD
} from '../../const.js';


const getDivisionFromRating = (rating)=> {
  if (rating < DIVISION_PROSPECT_THRESHOLD) {
    return DIVISION_ROOKIE_VALUE;

  } else if (rating > DIVISION_PROSPECT_THRESHOLD && rating < DIVISION_EXPERT_THRESHOLD) {
    return DIVISION_PROSPECT_VALUE;

  } else if (rating > DIVISION_EXPERT_THRESHOLD && rating < DIVISION_CHAMPION_THRESHOLD) {
    return DIVISION_EXPERT_VALUE;

  }

  return DIVISION_CHAMPION_VALUE;
};


const RatingShield = ({ rating, fontSize })=> {
  const divisionValue = getDivisionFromRating(rating);

  return (
    <Tooltip
      title={
        divisionValue === DIVISION_ROOKIE_VALUE ?
          DIVISION_ROOKIE_LABEL
        : divisionValue === DIVISION_PROSPECT_VALUE ?
          DIVISION_PROSPECT_LABEL
        : divisionValue === DIVISION_EXPERT_VALUE ?
          DIVISION_EXPERT_LABEL
        : divisionValue === DIVISION_CHAMPION_VALUE ?
          DIVISION_CHAMPION_LABEL
        :
          null
      }
    >
      <ShieldIcon
        sx={{
          fontSize: fontSize,
          marginLeft: 0.5,
          color: (
            divisionValue === DIVISION_ROOKIE_VALUE ?
              orange[700]
            : divisionValue === DIVISION_PROSPECT_VALUE ?
              grey[500]
            : divisionValue === DIVISION_EXPERT_VALUE ?
              yellow[600]
            : divisionValue === DIVISION_CHAMPION_VALUE ?
              purple[800]
            :
              null
          )
        }}
      />
    </Tooltip>
  );
};

RatingShield.defaultProps = {
  fontSize: 24
};

export default RatingShield;
