import React from 'react';

import StyledTable from './StyledTable.js';
import SummaryDataTableRow  from './SummaryDataTableRow.js';
import StyledGridItemWithHeader from './StyledGridItemWithHeader.js';


const PointsData = ({ summaryData })=> (
  <StyledGridItemWithHeader header="Points" gridItemProps={{ xs: 12, sm: 12, md: 6 }}>
    <StyledTable headers={[]}>

      <SummaryDataTableRow
        label="ELO / Round"
        value={summaryData.mean_points_gained_per_round}
      />

      <SummaryDataTableRow
        label="Season Points"
        value={summaryData.total_season_points}
      />

      <SummaryDataTableRow
        label="Season Points / Round"
        value={summaryData.season_points_per_round}
      />

      <SummaryDataTableRow
        label="Rounds / 500pt Reward"
        value={summaryData.rounds_per_post_track_reward}
      />

    </StyledTable>
  </StyledGridItemWithHeader>
);

export default PointsData;
