import 'chart.js/auto';

import React, { Component } from 'react';
import csv from 'csvtojson';

import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';

import {
  normalizeJsonData,
  calculateSummaryData 
} from './data.js';

import {
  DIVISION_ALL_VALUE,
  DIVISION_PROSPECT_VALUE,
  DIVISION_EXPERT_VALUE,
  DIVISION_PROSPECT_THRESHOLD,
  DIVISION_EXPERT_THRESHOLD,
  DIVISION_CHAMPION_THRESHOLD
} from './const.js';

import PeriodTab from './components/tabs/PeriodTab.js';
import SummaryTab from './components/tabs/SummaryTab.js';
import TopBar from './components/shared/TopBar.js';
import DivisionFilter from './components/shared/DivisionFilter.js';
import StyledGridItemWithHeader from './components/shared/StyledGridItemWithHeader.js';


class App extends Component {

  constructor() {
    super();

    this.state = {
      currentTab: 0,
      jsonData: [],
      summaryData: {},
      filteredJsonData: [],
      csvData: localStorage.getItem('dgv.csvData') || ''
    };

    this.handleCsvDataChange = this.handleCsvDataChange.bind(this);
    this.handleFilterDataChange = this.handleFilterDataChange.bind(this);
  }

  componentDidMount() {
    if (this.state.csvData) {
      this.handleCsvDataChange(this.state.csvData);
    }
  }

  handleCsvDataChange(csvData) {
    this.setState({ csvData });

    localStorage.setItem('dgv.csvData', csvData)

    if (csvData) {
      csv().fromString(csvData).then((jsonData)=> {
        const normalizedData = normalizeJsonData(jsonData);
        const summaryData = calculateSummaryData(normalizedData, normalizedData);

        this.setState({
          jsonData: normalizedData,
          filteredJsonData: normalizedData,
          summaryData: summaryData,
        });
      });
    }
  }

  handleFilterDataChange(selectedDivisionValue) {
    const { jsonData } = this.state;

    const filteredJsonData = filter(jsonData, (data)=> {
      if (selectedDivisionValue === DIVISION_ALL_VALUE) {
        return true;

      } else if (selectedDivisionValue === DIVISION_PROSPECT_VALUE) {
        return data.current_rating > DIVISION_PROSPECT_THRESHOLD && data.current_rating < DIVISION_EXPERT_THRESHOLD;

      } else if (selectedDivisionValue === DIVISION_EXPERT_VALUE) {
        return data.current_rating > DIVISION_EXPERT_THRESHOLD && data.current_rating < DIVISION_CHAMPION_THRESHOLD;

      } else {
        return data.current_rating > DIVISION_CHAMPION_THRESHOLD;

      }
    });

    this.setState({
      filteredJsonData: filteredJsonData,
      summaryData: calculateSummaryData(jsonData, filteredJsonData)
    });
  }

  render() {
    const {
      csvData,
      jsonData,
      currentTab,
      summaryData,
      filteredJsonData
    } = this.state;

    const { theme } = this.props;

    return (
      <Container maxWidth="lg">
        <Box sx={{ flexGrow: 1 }}>
          <TopBar
            currentTab={currentTab}
            onNavBtnClick={(tab)=> {
              this.setState({ currentTab: tab });
            }}
          />
        </Box>

        {
          isEmpty(jsonData) ?
            <Grid container spacing={theme.grid.spacing}>
              <StyledGridItemWithHeader
                header="Paste the exported CSV data in the text box below to view the charts"
                gridItemProps={{ xs: 12 }}
              >
                <TextField
                  fullWidth
                  multiline
                  rows={2}
                  maxRows={10}
                  value={csvData}
                  onChange={(e)=> {
                    this.handleCsvDataChange(e.target.value);
                  }}
                />
              </StyledGridItemWithHeader>
            </Grid>
          :
            <Grid container spacing={theme.grid.spacing}>
              <StyledGridItemWithHeader
                header="Filter by Division"
                gridItemProps={{ xs: 12 }}
                bodySx={{ padding: '20px' }}
              >
                <DivisionFilter onChange={this.handleFilterDataChange} />
              </StyledGridItemWithHeader>

              <SummaryTab
                index={0}
                currentTab={currentTab}
                csvData={csvData}
                summaryData={summaryData}
                filteredJsonData={filteredJsonData}
                onCsvDataChange={this.handleCsvDataChange}
              />

              <PeriodTab
                index={1}
                currentTab={currentTab}
                summaryData={summaryData}
                filteredJsonData={filteredJsonData}
              />
            </Grid>
        }
      </Container>
    );
  }
}

export default App;
