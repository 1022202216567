import React from 'react';
import { useTheme } from '@mui/material';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Grid from '@mui/material/Grid';

import CustomTabPanel from '../shared/CustomTabPanel.jsx';
import PeriodMetaTable from '../shared/PeriodMetaTable.js';
import StyledGridItemWithHeader from '../shared/StyledGridItemWithHeader.js';
import RatingAndRoundsByDayChart from '../charts/RatingAndRoundsByDayChart.js';
import RatingChangeByPeriodChart from '../charts/RatingChangeByPeriodChart.js';
import OutcomePercentByPeriodChart from '../charts/OutcomePercentByPeriodChart.js';

import {
  GROUPING_LABEL_HOURS,
  GROUPING_LABEL_DAY_OF_WEEK
} from '../../const.js';


const PeriodTab = ({ index, currentTab, summaryData, filteredJsonData })=> {
  const [ periodTab, setPeriodTab ] = React.useState(0);
  const theme = useTheme();

  return (
    <CustomTabPanel value={currentTab} index={index}>
      <Grid container spacing={theme.grid.spacing}>
        <StyledGridItemWithHeader bodySx={{ padding: '15px' }} gridItemProps={{ xs: 12 }}>
          <Tabs
            centered
            value={periodTab}
            onChange={(e, val)=> {
              setPeriodTab(val);
            }}
            aria-label="basic tabs example"
          >
            <Tab label="Date" {...a11yProps(0)} />
            <Tab label="Hourly" {...a11yProps(1)} />
            <Tab label="Daily" {...a11yProps(2)} />
          </Tabs>
        </StyledGridItemWithHeader>

        <CustomTabPanel value={periodTab} index={0}>
          <StyledGridItemWithHeader header="Rating and rounds by day" gridItemProps={{ xs: 12 }}>
            <RatingAndRoundsByDayChart summaryData={summaryData} />
          </StyledGridItemWithHeader>
        </CustomTabPanel>

        <CustomTabPanel value={periodTab} index={1}>
          <StyledGridItemWithHeader header="Rounds and rating change by time of day" gridItemProps={{ xs: 12 }}>
            <RatingChangeByPeriodChart 
              groupingLabel={GROUPING_LABEL_HOURS}
              groupedData={summaryData.rounds_by_hour_advanced}
            />
          </StyledGridItemWithHeader>

          <StyledGridItemWithHeader header="Time of day advanced data" wrapperSx={{ marginTop: '40px' }} gridItemProps={{ xs: 12 }}>
            <PeriodMetaTable 
              groupingLabel={GROUPING_LABEL_HOURS}
              groupedData={summaryData.rounds_by_hour_advanced}
            />
          </StyledGridItemWithHeader>

          <StyledGridItemWithHeader header="Outcome likelihood by time of day" wrapperSx={{ marginTop: '40px' }} gridItemProps={{ xs: 12 }}>
            <OutcomePercentByPeriodChart
              groupingLabel={GROUPING_LABEL_HOURS}
              groupedData={summaryData.rounds_by_hour_advanced}
            />
          </StyledGridItemWithHeader>
        </CustomTabPanel>

        <CustomTabPanel value={periodTab} index={2}>
          <StyledGridItemWithHeader header="Rounds and rating change by day of week" gridItemProps={{ xs: 12 }}>
            <RatingChangeByPeriodChart 
              groupingLabel={GROUPING_LABEL_DAY_OF_WEEK}
              groupedData={summaryData.rounds_by_day_of_week_advanced}
            />
          </StyledGridItemWithHeader>

          <StyledGridItemWithHeader header="Day of week advanced data" wrapperSx={{ marginTop: '40px' }} gridItemProps={{ xs: 12 }}>
            <PeriodMetaTable 
              groupingLabel={GROUPING_LABEL_DAY_OF_WEEK}
              groupedData={summaryData.rounds_by_day_of_week_advanced}
            />
          </StyledGridItemWithHeader>

          <StyledGridItemWithHeader header="Outcome likelihood by day of week" wrapperSx={{ marginTop: '40px' }} gridItemProps={{ xs: 12 }}>
            <OutcomePercentByPeriodChart
              groupingLabel={GROUPING_LABEL_DAY_OF_WEEK}
              groupedData={summaryData.rounds_by_day_of_week_advanced}
            />
          </StyledGridItemWithHeader>
        </CustomTabPanel>
      </Grid>
    </CustomTabPanel>
  );
};

const a11yProps = (index)=> {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default PeriodTab;
