import moment from 'moment';

import get from 'lodash/get';
import map from 'lodash/map';
import last from 'lodash/last';
import first from 'lodash/first';
import sumBy from 'lodash/sumBy';
import maxBy from 'lodash/maxBy';
import minBy from 'lodash/minBy';
import round from 'lodash/round';
import filter from 'lodash/filter';
import meanBy from 'lodash/meanBy';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/toPairs';
import reverse from 'lodash/reverse';
import mapKeys from 'lodash/mapKeys';
import snakeCase from 'lodash/snakeCase';
import takeRight from 'lodash/takeRight';
import takeWhile from 'lodash/takeWhile';
import findIndex from 'lodash/findIndex';
import slice from 'lodash/slice';

import {
  BASE_RATING,
  DAYS_IN_WEEK,
  HOURS_IN_DAY,
  FINISHES_NEGATIVE,
  FINISHES_POSITIVE,
  FINISHES_EVEN
} from './const.js';


/**
 * Normalize the JSON data to use integers and dates. It also appends the
 * running current rating to each round to make it easier to track. We also
 * make the object keys snakecase for consistent index lookups.
 */
export const normalizeJsonData = (jsonData)=> {
  var currentRating = BASE_RATING;

  var previousRatingChange = null;

  return map(reverse(jsonData), (matchData, idx)=> {
    const normalizedData = mapKeys(matchData, (value, key)=> {
      return snakeCase(key);
    });

    const ratingChange = parseInt(normalizedData["rating_change"]);

    const shouldMarkAsBounce = (
      previousRatingChange !== null &&
      previousRatingChange < 0 &&
      ratingChange < 0
    );

    currentRating += ratingChange;
    previousRatingChange = ratingChange;

    return {
      ...normalizedData,
      date: moment(normalizedData.date, 'DD/MM/YYYY hh:mm'),
      match_num: idx + 1,
      rating_change: ratingChange,
      season_points: parseInt(normalizedData["season_points"]),
      current_rating: currentRating,
      bounce: shouldMarkAsBounce ? 1 : 0
    };
  });
};


/**
 * Calculate all of the tabular summary data for display
 */
export const calculateSummaryData = (jsonData, filteredJsonData)=> {
  const roundsByDate = groupRoundsByDate(filteredJsonData);
  const roundsByHour = groupRoundsByHour(filteredJsonData);
  const roundsByDayOfWeek = groupRoundsByDayOfWeek(filteredJsonData);
  const groupedRatingChanges = groupByRatingChange(filteredJsonData);

  return {
    total_rounds: filteredJsonData.length,
    rounds_by_date: roundsByDate,
    rounds_by_hour: roundsByHour,
    rounds_by_day_of_week: roundsByDayOfWeek,
    grouped_rating_changes: groupedRatingChanges,
    most_rounds_for_day: getMostRoundsInDay(roundsByDate),
    rounds_per_day: getRoundsPerDay(jsonData, filteredJsonData),
    current_rating: getCurrentRating(jsonData),
    highest_rating: getHighestRating(jsonData),
    mean_points_gained_per_round: getMeanPointsPerRound(filteredJsonData),
    last_twenty_rating: getLastTwentyRating(filteredJsonData),
    bounce_back_percentage: getBounceBackPercentage(filteredJsonData, groupedRatingChanges),
    longest_green_streak: getLongestGreenStreak(filteredJsonData),
    longest_red_streak: getLongestRedStreak(filteredJsonData),
    longest_non_green_streak: getLongestNonGreenStreak(filteredJsonData),
    longest_non_red_streak: getLongestNonRedStreak(filteredJsonData),
    longest_points_gained_streak: getLongestPointsGainedStreak(filteredJsonData),
    longest_points_lost_streak: getLongestPointsLostStreak(filteredJsonData),
    rounds_by_hour_advanced: getAdvancedGroupedRoundMetrics(filteredJsonData, roundsByHour),
    rounds_by_day_of_week_advanced: getAdvancedGroupedRoundMetrics(filteredJsonData, roundsByDayOfWeek),
    total_season_points: getTotalSeasonPoints(jsonData),
    season_points_per_round: getSeasonPointsPerRound(filteredJsonData),
    rounds_per_post_track_reward: getRoundsPerPostTrackReward(filteredJsonData)
  };
};


/**
 * Bounce back percentage shows how well the player recovers after consequtive
 * losses. A higher percentage shows their ability to avoice a long slump.
 */
const getBounceBackPercentage = (jsonData, groupedRatingChanges)=>
  round((1 - (sumBy(jsonData, 'bounce') / get(groupedRatingChanges[FINISHES_NEGATIVE], 'total'))) * 100) || 0;


/**
 * Last twenty rating describes the points gained over the last twenty matches.
 */
const getLastTwentyRating = (jsonData)=>
  sumBy(takeRight(jsonData, 20), 'rating_change');


/**
 * Mean points per round shows how many points the player typically gains per round.
 */
const getMeanPointsPerRound = (jsonData)=>
  round(meanBy(jsonData, 'rating_change'), 2) || 0;


/**
 * The highest rating describes the best rating the player has achieved thus far.
 */
const getHighestRating = (jsonData)=>
  get(maxBy(jsonData, 'current_rating'), 'current_rating', BASE_RATING);


/**
 * The current rating describes the current rating for the player.
 */
const getCurrentRating = (jsonData)=>
  get(last(jsonData), 'current_rating', BASE_RATING);


/**
 * Rounds per day describes the average number of rounds the player plays in a day.
 */
const getRoundsPerDay = (jsonData, filteredJsonData)=>
  round(filteredJsonData.length / (moment().diff(moment(get(first(jsonData), 'date'), 1), 'days')) || 1, 1);


/**
 * Most rounds in a day describes the day in which they played the most rounds.
 */
const getMostRoundsInDay = (roundsByDate)=>
  maxBy(toPairs(roundsByDate), (pairs)=> last(pairs).length);


/**
 * Group rounds by the actual date
 */
const groupRoundsByDate = (jsonData)=>
  groupBy(jsonData, (o)=> o.date.format('MM/DD/YYYY')) || {};


/**
 * Group rounds by the hour they played
 */
const groupRoundsByHour = (jsonData)=> ({
  ...HOURS_IN_DAY,
  ...groupBy(jsonData, (o)=> o.date.hour())
});


/**
 * Group rounds by the day of the week they played
 */
const groupRoundsByDayOfWeek = (jsonData)=> ({
  ...DAYS_IN_WEEK,
  ...groupBy(jsonData, (o)=> o.date.day())
});


/**
 * Calculate advanced metrics about a grouping of rounds. The groupedRounds
 * should be in an object format with each key containing an array of rounds as
 * the value.
 */
const getAdvancedGroupedRoundMetrics = (jsonData, groupedRounds)=> {
  return map(groupedRounds, (rounds)=> {
    const totalChange = sumBy(rounds, 'rating_change');

    return {
      count: rounds.length,
      percent_of_total: (
        rounds.length > 0 ?
          round((rounds.length / jsonData.length) * 100, 1)
        :
          0
      ),
      total_change: totalChange,
      average_change: round(totalChange / rounds.length, 1) || 0,
      gain_percent: round((filter(rounds, (round)=> round.rating_change > 0).length / rounds.length) * 100, 1) || 0,
      even_percent: round((filter(rounds, (round)=> round.rating_change === 0).length / rounds.length) * 100, 1) || 0,
      loss_percent: round((filter(rounds, (round)=> round.rating_change < 0).length / rounds.length) * 100, 1) || 0
    };
  })
};


/**
 * Creates a grouping showing the totals for point gained, lost, and even.
 */
const groupByRatingChange = (jsonData)=> {
  const changeTypes = {
    [FINISHES_POSITIVE]: { total: 0, percent: 0 },
    [FINISHES_NEGATIVE]: { total: 0, percent: 0 },
    [FINISHES_EVEN]: { total: 0, percent: 0 }
  };

  forEach(jsonData, (res)=> {
    if (res.rating_change === 0) {
      changeTypes[FINISHES_EVEN].total += 1;
    } else if (res.rating_change > 0) {
      changeTypes[FINISHES_POSITIVE].total += 1;
    } else {
      changeTypes[FINISHES_NEGATIVE].total += 1;
    }
  });

  changeTypes[FINISHES_EVEN].percent = round((changeTypes[FINISHES_EVEN].total / jsonData.length) * 100) || 0;
  changeTypes[FINISHES_POSITIVE].percent = round((changeTypes[FINISHES_POSITIVE].total / jsonData.length) * 100) || 0;
  changeTypes[FINISHES_NEGATIVE].percent = round((changeTypes[FINISHES_NEGATIVE].total / jsonData.length) * 100) || 0;

  return changeTypes;
};


/**
 * Utility function to generate a list of streaks based on a
 * streakQualifierFunc. This creates a meta object for each streak summarzing
 * the streak information. This meta is meant to be consumed by streak parsers.
 *
 * Note: We're doing some index hacking here since lodash doesn't have a
 * convenient way to chunk data based on criteria. This works but there is
 * probably a more elegant way to do this.
 */
const generateStreakMeta = (jsonData, streakQualifierFunc)=> {
  const allStreaks = [];

  for (var i = 0; i < jsonData.length; i++) {
    const streak = takeWhile(slice(jsonData, i), streakQualifierFunc);
    const idx = findIndex(jsonData, last(streak)) + 1;

    if (idx > i) {
      i = idx;
      allStreaks.push(streak);
    }
  }

  return map(allStreaks, (streak)=> {
    return {
      len: streak.length,
      points_gained: sumBy(streak, 'rating_change'),
      start_match_num: get(first(streak), 'match_num'),
      end_match_num: get(last(streak), 'match_num'),
      start_date: get(first(streak), 'date') || moment(),
      end_date: get(last(streak), 'date') || moment()
    };
  });
};


/**
 * Return the longest streak where only positive finishes occurred
 */
const getLongestGreenStreak = (jsonData)=>
  maxBy(generateStreakMeta(jsonData, (m)=> m.rating_change > 0), 'len');


/**
 * Return the longest streak where only negative finishes occurred
 */
const getLongestRedStreak = (jsonData)=>
  maxBy(generateStreakMeta(jsonData, (m)=> m.rating_change < 0), 'len');


/**
 * Return the longest streak where negative and even finishes occurred
 */
const getLongestNonGreenStreak = (jsonData)=>
  maxBy(generateStreakMeta(jsonData, (m)=> m.rating_change <= 0), 'len');


/**
 * Return the longest streak where positive and even finishes occurred
 */
const getLongestNonRedStreak = (jsonData)=>
  maxBy(generateStreakMeta(jsonData, (m)=> m.rating_change >= 0), 'len');


/**
 * Return the streak where the most points were gained no matter the length
 */
const getLongestPointsGainedStreak = (jsonData)=>
  maxBy(generateStreakMeta(jsonData, (m)=> m.rating_change >= 0), 'points_gained');


/**
 * Return the streak where the most points were lost no matter the length
 */
const getLongestPointsLostStreak = (jsonData)=>
  minBy(generateStreakMeta(jsonData, (m)=> m.rating_change <= 0), 'points_gained');

/**
 * Return the total season points accumulated this season
 */
const getTotalSeasonPoints = (jsonData)=>
  sumBy(jsonData, 'season_points');

/**
 * Return the average amount of season points accumulated in each round played
 */
const getSeasonPointsPerRound = (jsonData)=>
  round(getTotalSeasonPoints(jsonData) / jsonData.length, 1) || 0;

/**
 * Return the number of rounds it takes to reach a 500pt reward
 */
const getRoundsPerPostTrackReward = (jsonData)=>
  round(500 / getSeasonPointsPerRound(jsonData), 1) || 0;
