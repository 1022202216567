import React from 'react';
import { useTheme } from '@mui/material';

import StyledTable from './StyledTable.js';
import RatingShield from './RatingShield.js';
import SummaryDataTableRow  from './SummaryDataTableRow.js';
import StyledGridItemWithHeader from './StyledGridItemWithHeader.js';


const RatingData = ({ summaryData })=> {
  const theme = useTheme();

  return (
    <StyledGridItemWithHeader header="Rating" gridItemProps={{ xs: 12, sm: 12, md: 6 }}>
      <StyledTable headers={[]}>

        <SummaryDataTableRow
          label="Current Level"
          value="443"
        />

        <SummaryDataTableRow
          label="Current MP Rating"
          value={
            <span>
              <RatingShield rating={summaryData.current_rating} fontSize="0.875rem" /> { summaryData.current_rating }
            </span>
          }
        />

        <SummaryDataTableRow
          label="Best MP Rating"
          value={
            <span>
              <RatingShield rating={summaryData.highest_rating} fontSize="0.875rem" /> { summaryData.highest_rating }
            </span>
          }
        />

        <SummaryDataTableRow
          label="Last 20"
          valueColor={
            summaryData.last_twenty_rating >= 0 ?
              theme.palette.success.main
            : summaryData.last_twenty_rating === 0 ?
              null
            :
              theme.palette.error.main
          }
          value={
            <span>
              { summaryData.last_twenty_rating >= 0 ? '+' : null }{ summaryData.last_twenty_rating }
            </span>
          }
        />

      </StyledTable>
    </StyledGridItemWithHeader>
  );
};

export default RatingData;
