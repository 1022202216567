import React from 'react';

import Grid from '@mui/material/Grid';


const CustomTabPanel = ({ children, value, index, ...other })=> {
  if (value !== index) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        { children }
      </div>
    </Grid>
  );
}

export default CustomTabPanel;
