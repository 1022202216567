import React from 'react';
import { alpha, useTheme } from '@mui/material';

import { Chart } from 'react-chartjs-2';

import get from 'lodash/get';
import map from 'lodash/map';
import keys from 'lodash/keys';
import maxBy from 'lodash/maxBy';
import values from 'lodash/values';

import { getTooltipDefaults } from './helpers.js';


const RatingAndRoundsByDayChart = ({ summaryData })=> {
  const theme = useTheme();

  return (
    <Chart
      options={{
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        plugins: {
          title: {
            display: false
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: theme.palette.text.secondary
            }
          },
          tooltip: getTooltipDefaults(theme),
        },
        elements: {
          point: {
            borderWidth: 1,
            radius: 1,
            backgroundColor: theme.palette.background.default
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              drawOnChartArea: true,
            },
            ticks: {
              color: theme.palette.text.secondary,
            },
            title: {
              display: false,
            }
          },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            border: {
              display: true,
              color: theme.palette.background.default,
            },
            grid: {
              color: theme.palette.background.default,
            },
            ticks: {
              stepSize: 50,
              color: theme.palette.text.secondary,
            },
            title: {
              display: true,
              text: 'Rating',
              color: theme.palette.text.secondary,
            }
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: theme.palette.text.secondary,
            },
            title: {
              display: true,
              text: 'Rounds',
              color: theme.palette.text.secondary,
            }
          },
        },
      }}
      data={{
        labels: keys(summaryData.rounds_by_date),
        datasets: [
          {
            label: 'Rating',
            type: 'line',
            fill: true,
            data: values(map(summaryData.rounds_by_date, (rounds)=> get(maxBy(rounds, 'current_rating'), 'current_rating'))),
            borderColor: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.2),
            yAxisID: 'y',
            tension: 0.3,
          },
          {
            label: 'Rounds Played',
            type: 'bar',
            data: values(map(summaryData.rounds_by_date, 'length')),
            borderColor: theme.palette.secondary.dark,
            backgroundColor: theme.palette.secondary.dark,
            yAxisID: 'y1',
          },
        ],
      }}
    />
  );
};

export default RatingAndRoundsByDayChart;
