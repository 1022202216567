import React from 'react';
import { alpha, useTheme } from '@mui/material';

import { Chart } from 'react-chartjs-2';

import map from 'lodash/map';
import keys from 'lodash/keys';
import values from 'lodash/values';

import {
  HOUR_LABELS,
  DAYS_OF_WEEK_LABELS,
  GROUPING_LABEL_HOURS,
  GROUPING_LABEL_DAY_OF_WEEK
} from '../../const.js';


const OutcomePercentByPeriodChart = ({ groupingLabel, groupedData })=> {
  const theme = useTheme();

  return (
    <Chart
      options={{
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        plugins: {
          title: {
            display: false
          },
          tooltip: {
            titleColor: theme.palette.text.primary,
            bodyColor: theme.palette.text.secondary,
            bodySpacing: 10,
            titleMarginBottom: 10,
            borderColor: theme.palette.background.default,
            boxPadding: 5,
            padding: 20,
            backgroundColor: theme.palette.background.default,
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';

                if (label) {
                  label += ': ';
                }

                if (context.parsed.y !== null) {
                  label += `${context.parsed.y}%`;
                }

                return label;
              }
            }
          },
          legend: {
            display: true,
            position: 'top',
            labels: {
              color: theme.palette.text.secondary
            }
          },
        },
        elements: {
          point: {
            borderWidth: 1,
            radius: 1,
            backgroundColor: theme.palette.background.default
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              drawOnChartArea: true,
            },
            ticks: {
              color: theme.palette.text.secondary,
            },
            title: {
              display: false,
            }
          },
          y: {
            type: 'linear',
            stacked: true,
            display: true,
            position: 'left',
            color: theme.palette.text.secondary,
            max: 100,
            border: {
              display: true,
              color: theme.palette.background.default,
            },
            grid: {
              color: theme.palette.background.default,
            },
            ticks: {
              color: theme.palette.text.secondary,
              callback: function(value, index, ticks) {
                return `${value}%`;
              }
            },
            title: {
              display: false,
            }
          }
        },
      }}
      data={{
        labels: map(keys(groupedData), (key)=> {
          return (
            groupingLabel === GROUPING_LABEL_HOURS ?
              HOUR_LABELS[key]
            : groupingLabel === GROUPING_LABEL_DAY_OF_WEEK ?
              DAYS_OF_WEEK_LABELS[key]
            :
              key
          )
        }),
        datasets: [
          {
            label: 'Even Percent',
            type: 'line',
            fill: true,
            data: values(map(groupedData, 'even_percent')),
            borderColor: theme.palette.info.main,
            backgroundColor: alpha(theme.palette.info.main, 0.2),
            tension: 0.3
          },
          {
            label: 'Loss Percent',
            type: 'line',
            fill: true,
            data: values(map(groupedData, 'loss_percent')),
            borderColor: theme.palette.error.main,
            backgroundColor: alpha(theme.palette.error.main, 0.2),
            tension: 0.3
          },
          {
            label: 'Gain Percent',
            type: 'line',
            fill: true,
            data: values(map(groupedData, 'gain_percent')),
            borderColor: theme.palette.success.main,
            backgroundColor: alpha(theme.palette.success.main, 0.2),
            tension: 0.1
          },
        ],
      }}
    />
  );
};

export default OutcomePercentByPeriodChart;
