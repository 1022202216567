import get from 'lodash/get';
import fill from 'lodash/fill';
import range from 'lodash/range';
import invert from 'lodash/invert';
import zipObject from 'lodash/zipObject';

export const BASE_RATING = 500;

export const DAYS_IN_WEEK = zipObject(range(7), fill(Array(7), []));
export const HOURS_IN_DAY = zipObject(range(24), fill(Array(24), []));

export const DIVISION_ALL_VALUE = 'all';
export const DIVISION_ROOKIE_VALUE = 'rookie';
export const DIVISION_PROSPECT_VALUE = 'prospect';
export const DIVISION_EXPERT_VALUE = 'expert';
export const DIVISION_CHAMPION_VALUE = 'champion';

export const DIVISION_PROSPECT_THRESHOLD = 400;
export const DIVISION_EXPERT_THRESHOLD = 550;
export const DIVISION_CHAMPION_THRESHOLD = 800;

export const DIVISION_ALL_LABEL = 'All';
export const DIVISION_ROOKIE_LABEL = 'Rookie Division';
export const DIVISION_PROSPECT_LABEL = 'Prospect Division';
export const DIVISION_EXPERT_LABEL = 'Expert Division';
export const DIVISION_CHAMPION_LABEL = 'Champion Division';

export const DIVISION_OPTIONS = {
  [DIVISION_ALL_LABEL]: DIVISION_ALL_VALUE,
  [DIVISION_CHAMPION_LABEL]: DIVISION_CHAMPION_VALUE,
  [DIVISION_EXPERT_LABEL]: DIVISION_EXPERT_VALUE,
  [DIVISION_PROSPECT_LABEL]: DIVISION_PROSPECT_VALUE
};

export const FINISHES_NEGATIVE = 'Negative Finishes';
export const FINISHES_POSITIVE = 'Positive Finishes';
export const FINISHES_EVEN = 'Even Finishes';

export const GROUPING_LABEL_HOURS = 'Hours';
export const GROUPING_LABEL_DAY_OF_WEEK = 'Day of Week';

export const HOUR_LABELS = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM'
];

export const DAYS_OF_WEEK_LABELS = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thurs',
  'Fri',
  'Sat'
];

export const FIRST = 'First';
export const FIRST_TIED = 'First (Tied)';
export const SECOND = 'Second';
export const SECOND_TIED = 'Second (Tied)';
export const THIRD = 'Third';
export const THIRD_TIED = 'Third (Tied)';
export const FOURTH = 'Fourth';
export const RETIRED = 'Retired';

export const positionDefaults = {
  [FIRST]: 0,
  [FIRST_TIED]: 0,
  [SECOND]: 0,
  [SECOND_TIED]: 0,
  [THIRD]: 0,
  [THIRD_TIED]: 0,
  [FOURTH]: 0,
  [RETIRED]: 0
}

/**
 * Normalize the labels so they are in alphabetical order. It's not ideal for
 * these charts since 1 and T1 are so separated. This at least gets things in
 * order by allowing us to cast the keys.
 */
export const positionToAlphaMap = {
  '1': FIRST,
  'T1': FIRST_TIED,
  '2': SECOND,
  'T2': SECOND_TIED,
  '3': THIRD,
  'T3': THIRD_TIED,
  '4': FOURTH,
  'R': RETIRED
};

export const alphaToPositionMap = invert(positionToAlphaMap);

export const convertPositonAlpha = (position)=>
  get(positionToAlphaMap, position);

export const convertAlphaToPosition = (alpha)=>
  get(alphaToPositionMap, alpha);
