import React from 'react';
import { useTheme } from '@mui/material';

import round from 'lodash/round';
import countBy from 'lodash/countBy';
import mapKeys from 'lodash/mapKeys';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TableCell from '@mui/material/TableCell';

import { convertAlphaToPosition } from '../../const.js';

import StreakData from '../shared/StreakData.js';
import RatingData from '../shared/RatingData.js';
import RoundsData from '../shared/RoundsData.js';
import PointsData from '../shared/PointsData.js';
import CustomTabPanel from '../shared/CustomTabPanel.jsx';
import StyledTable, { StyledTableRow } from '../shared/StyledTable.js';

import RatingByRoundChart from '../charts/RatingByRoundChart.js';
import PlacePercentagesChart from '../charts/PlacePercentagesChart.js';
import OutcomePercentagesChart  from '../charts/OutcomePercentagesChart.js';

import StyledGridItemWithHeader from '../shared/StyledGridItemWithHeader.js';

import {
  FINISHES_NEGATIVE,
  FINISHES_POSITIVE,
  FINISHES_EVEN,
  positionDefaults,
  convertPositonAlpha,
  FIRST,
  FIRST_TIED,
  SECOND,
  SECOND_TIED,
  THIRD,
  THIRD_TIED,
  FOURTH,
  RETIRED
} from '../../const.js';


const SummaryTab = ({ index, csvData, currentTab, summaryData, filteredJsonData, onCsvDataChange })=> {
  const theme = useTheme();

  const positionData = {
    ...positionDefaults,
    ...mapKeys(countBy(filteredJsonData, 'position'), (value, key)=> convertPositonAlpha(key))
  };

  return (
    <CustomTabPanel value={currentTab} index={index}>
      <Grid container spacing={theme.grid.spacing}>

        <RatingData summaryData={summaryData} />
        <RoundsData summaryData={summaryData} />
        <PointsData summaryData={summaryData} />
        <StreakData summaryData={summaryData} />

        <StyledGridItemWithHeader
          header="Rating by round"
          gridItemProps={{ xs: 12 }}
          bodySx={{
            padding: {
              'xs': '30px 10px 10px 10px',
              'sm': '30px 10px 10px 10px',
              'md': '50px 30px 30px 30px'
            }
          }}
        >
          <RatingByRoundChart jsonData={filteredJsonData} />
        </StyledGridItemWithHeader>

        <StyledGridItemWithHeader header="Round outcome percentages" gridItemProps={{ sm: 12, md: 6 }}>
          <OutcomePercentagesChart groupedRatingChanges={summaryData.grouped_rating_changes} />

          <StyledTable headers={['Outcome', '# Games', '% Games']} tableSx={{ marginTop: '30px' }}>
            <RoundOutcomeTableRow label={FINISHES_POSITIVE} summaryData={summaryData} />
            <RoundOutcomeTableRow label={FINISHES_EVEN} summaryData={summaryData} />
            <RoundOutcomeTableRow label={FINISHES_NEGATIVE} summaryData={summaryData} />
          </StyledTable>
        </StyledGridItemWithHeader>

        <StyledGridItemWithHeader header="Place percentages" gridItemProps={{ sm: 12, md: 6 }}>
          <PlacePercentagesChart positionData={positionData} />

          <StyledTable headers={['Place', '# Games', '% Games']} tableSx={{ marginTop: '30px' }}>
            <PlacePercentTableRow label={FIRST} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={FIRST_TIED} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={SECOND} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={SECOND_TIED} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={THIRD} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={THIRD_TIED} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={FOURTH} positionData={positionData} summaryData={summaryData} />
            <PlacePercentTableRow label={RETIRED} positionData={positionData} summaryData={summaryData} />
          </StyledTable>
        </StyledGridItemWithHeader>

        <StyledGridItemWithHeader
          header="Paste the exported CSV data in the text box below to view the charts"
          gridItemProps={{ xs: 12 }}
        >
          <TextField
            fullWidth
            multiline
            rows={2}
            maxRows={10}
            value={csvData}
            onChange={(e)=> {
              onCsvDataChange(e.target.value);
            }}
          />
        </StyledGridItemWithHeader>

      </Grid>
    </CustomTabPanel>
  );
};

const RoundOutcomeTableRow = ({ label, summaryData })=> (
  <StyledTableRow>
    <TableCell component="th" scope="row" sx={{ color: theme => theme.palette.text.secondary }}>
      { label }
    </TableCell>
    <TableCell component="th" scope="row">
      { summaryData.grouped_rating_changes[label].total }
    </TableCell>
    <TableCell component="th" scope="row">
      { summaryData.grouped_rating_changes[label].percent }%
    </TableCell>
  </StyledTableRow>
);

const PlacePercentTableRow = ({ label, positionData, summaryData })=> (
  <StyledTableRow>
    <TableCell component="th" scope="row" sx={{ color: theme => theme.palette.text.secondary, width: '50%' }}>
      { convertAlphaToPosition(label) }
    </TableCell>
    <TableCell component="th" scope="row">
      { positionData[label] }
    </TableCell>
    <TableCell component="th" scope="row">
      { round((positionData[label] / summaryData.total_rounds) * 100) || 0 }%
    </TableCell>
  </StyledTableRow>
);

export default SummaryTab;
