import React from 'react';

import map from 'lodash/map';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    borderBottom: 0,
  },
  'td, th, &:first-of-type th': {
    borderBottom: `1px solid ${theme.palette.background.default}`,
  },
}));


const StyledTable = ({ headers, tableSx, children })=> {
  return (
    <TableContainer component={Paper} elevation={0}>
      <Table size="small" aria-label="simple table" sx={tableSx}>
        <TableHead>
          <StyledTableRow>
            {
              map(headers, (header)=> (
                <TableCell key={header} sx={{ color: theme => theme.palette.text.secondary }}>
                  { header }
                </TableCell>
              ))
            }
          </StyledTableRow>
        </TableHead>
        <TableBody>
          { children }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export {
  StyledTableRow
};

export default StyledTable;
