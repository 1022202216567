import React from 'react';
import { useTheme } from '@mui/material';

import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import get from 'lodash/get';
import sum from 'lodash/sum';
import keys from 'lodash/keys';
import first from 'lodash/first';
import round from 'lodash/round';
import values from 'lodash/values';

import { getTooltipDefaults } from './helpers.js';
import { positionDefaults, convertAlphaToPosition } from '../../const.js';


const PlacePercentagesChart = ({ positionData })=> {
  const theme = useTheme();

  return (
    <Doughnut
      plugins={[ChartDataLabels]}
      options={{
        responsive: true,
        plugins: {
          datalabels: {
            anchor: 'center',
            color: function(context) {
              return (
                context.dataIndex >= 4 ?
                  theme.palette.text.primary
                :
                  theme.palette.background.paper
              );
            },
            display: function(context) {
              var dataset = context.dataset;
              var value = dataset.data[context.dataIndex];
              var totalRounds = sum(dataset.data);

              // show labels if at least 5% of the chart
              return round(value / totalRounds, 2) >= 0.05;
            },
            formatter: function(value, context) {
              return convertAlphaToPosition(context.chart.data.labels[context.dataIndex]);
            },
            font: {
              size: 16,
              weight: 'bold',
              family: '"Roboto","Helvetica","Arial",sans-serif',
            },
          },
          legend: {
            display: false,
            position: 'top',
            labels: {
              boxWidth: 20,
              padding: 20,
              generateLabels: function(chart) {
                var data = chart.data;

                if (data.labels.length && data.datasets.length) {
                  return data.labels.map(function(label, i) {
                    var ds = data.datasets[0];
                    var meta = chart.getDatasetMeta(0);

                    return {
                      text: convertAlphaToPosition(label),
                      fontColor: theme.palette.text.secondary,
                      fillStyle: ds.backgroundColor[i],
                      strokeStyle: theme.palette.background.paper,
                      lineWidth: '1px',
                      hidden: isNaN(ds.data[i]) || meta.data[i].hidden,
                      index: i
                    };
                  });
                }
                return [];
              }
            }
          },
          tooltip: {
            ...getTooltipDefaults(theme),
            callbacks: {
              title: function(context) {
                let title = get(first(context), 'label') || '';

                if (title) {
                  title = convertAlphaToPosition(title);
                }

                return title;
              }
            }
          },
        },
      }}
      data={{
        labels: keys(positionDefaults),
        datasets: [
          {
            label: 'Rounds',
            data: values(positionData),
            backgroundColor: [
              '#f1c232', // 1
              '#ffd966', // T1
              '#fff2cc', // 2
              '#f4cccc', // T2
              '#ea9999', // 3
              '#e06666', // T3
              '#cc0000', // 4
              '#990000' // R
            ],
            borderWidth: 2,
            borderColor: theme.palette.background.paper
          }
        ]
      } }
    />
  );
};

export default PlacePercentagesChart;
