import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { basicDropShadow, boxWithShadowStyle } from '../../theme.js';

const StyledGridItemWithHeader = ({ header, headerSx, bodySx, wrapperSx, gridItemProps, children })=> (
  <Grid item { ...gridItemProps }>
    <Paper square={false} sx={{ ...boxWithShadowStyle, ...wrapperSx }}>
        {
          header &&
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                padding: '10px 15px',
                backgroundColor: 'transparent',
                boxShadow: basicDropShadow,
                ...headerSx
              }}
            >
              { header }
            </Typography>
        }

          <Box
            className="paper-wrapper-body"
            sx={{
              padding: '10px',
              ...bodySx
            }}
          >
            { children }
        </Box>
    </Paper>
  </Grid>
);

StyledGridItemWithHeader.defaultProps = {
  wrapperSx: {},
  headerSx: {},
  bodySx: {}
};

export default StyledGridItemWithHeader;
