import React from 'react';

import Tooltip from '@mui/material/Tooltip';

import StyledTable from './StyledTable.js';
import SummaryDataTableRow  from './SummaryDataTableRow.js';
import StyledGridItemWithHeader from './StyledGridItemWithHeader.js';


const RoundsData = ({ summaryData })=> (
  <StyledGridItemWithHeader header="Rounds" gridItemProps={{ xs: 12, sm: 12, md: 6 }}>
    <StyledTable headers={[]}>

      <SummaryDataTableRow
        label="Total Rounds"
        value={summaryData.total_rounds}
      />

      <SummaryDataTableRow
        label="Rounds / Day"
        value={summaryData.rounds_per_day}
      />

      <SummaryDataTableRow
        label="Most Rounds / Day"
        value={
          summaryData.most_rounds_for_day ?
            <span>
              <Tooltip title={summaryData.most_rounds_for_day[0]}>
                { summaryData.most_rounds_for_day[1].length }
              </Tooltip>
            </span>
          :
            0
        }
      />

      <SummaryDataTableRow
        label="Bounce Back %"
        value={(
          <span>
            { summaryData.bounce_back_percentage }%
          </span>
        )}
      />

    </StyledTable>
  </StyledGridItemWithHeader>
);

export default RoundsData;
