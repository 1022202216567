import React from 'react';

import map from 'lodash/map';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { DIVISION_OPTIONS, DIVISION_ALL_VALUE } from '../../const.js';


const DivisionFilter = ({ onChange })=> {
  const [ divisionFilter, setDivisionFilter ] = React.useState(DIVISION_ALL_VALUE);

  return (
    <FormControl fullWidth>
      <Select
        variant="standard"
        labelId="filter-by-division"
        id="demo-simple-select"
        value={divisionFilter}
        label="Filter by Division"
        onChange={(e)=> {
          const selectedOption = e.target.value;

          setDivisionFilter(selectedOption);
          onChange(selectedOption);
        }}
      >
        {
          map(DIVISION_OPTIONS, (value, label)=> (
            <MenuItem key={value} value={value}>
              { label }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
};

export default DivisionFilter;
